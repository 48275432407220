<template>
  <v-row>
    <v-col cols="12" md="4" v-if="loadingData">
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    </v-col>
    <v-col v-else>
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-row no-gutters>
            <v-col cols="12">
              <span :class="titleClasses">Cliente:</span>
              <span :class="textClasses">{{ clients }}</span>
            </v-col>

            <v-col cols="12">
              <span :class="titleClasses">Centro:</span>
              <span :class="textClasses">{{ centers }}</span>
            </v-col>

            <v-col cols="12">
              <span :class="titleClasses">Formador:</span>
              <span :class="textClasses">{{ sessionData.trainer_name }}</span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-row no-gutters>
            <v-col cols="12">
              <span :class="titleClasses">Tipo:</span>
              <span :class="textClasses">{{ sessionData.category_name }}</span>
            </v-col>

            <v-col cols="12">
              <span :class="titleClasses">Lugar:</span>
              <span :class="textClasses">
                {{ sessionData.course_address }}, {{ sessionData.course_city }}
              </span>
            </v-col>

            <v-col cols="12">
              <span :class="titleClasses">Horario:</span>
              <span :class="textClasses"
                >{{ sessionData.start_time }} - {{ sessionData.end_time }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    sessionData: { type: Object, required: true },
    loadingData: { type: Boolean, required: true },
  },
  data: () => ({
    titleClasses: "text-subtitle-2 font-weight-bold mr-2",
    textClasses: "text-body-2",
  }),
  computed: {
    clients() {
      return this.sessionData.clients.map(c => c.name).join(", ");
    },
    centers() {
      return this.sessionData.centers.map(c => c.name).join(", ");
    },
  },
};
</script>

<style scoped></style>
